<template>
  <div
    :class="$vuetify.breakpoint.mobile ? 'center' : '--halfcolor-gradient'"
    class="position-relative vector-bg secondary"
  >
    <!-- <img
      class="position-absolute bottom-0 start-0"
      src="@/assets/images/page-img/Vector_14.png"
    > -->
  <section class="container-prosker m-0">
    <!-- <div id="circle-small"></div>
    <div id="circle-medium"></div>
    <div id="circle-large"></div>
    <div id="circle-xlarge"></div>
    <div class="d-xl-block d-none" id="circle-xxlarge"></div> -->
    <v-row class="no-gutters">
      <v-col cols="12" md="6" class="text-left side-wrapper">
        <div
          class="side-container"
          :style="{
            backgroundImage: `url(${require('@/assets/images/page-img/Frame_15.png')})`,
            backgroundPosition: ' right top',
            backgroundRepeat: 'no-repeat',
          }"
        >
          <div class="d-none d-md-block">
            <router-link :to="{ name: 'social.list' }" class="sign-in-logo position-absolute top-0 start-0 mt-4" style="z-index: 9999;">
              <img
                src="../../assets/images/logo/logo-prosk.png"
                class="img-fluid"
                alt="logo"
              />
            </router-link>
            <div class="info-container min-vh-100 d-flex flex-column position-relative justify-content-center">
                <img v-if="chooseBanner" :src="chooseBanner" class="mtop-15 img-fluid mt-6" alt="banner" />
                <div v-if="this.$route.name==='auth1.sign-in1'">
                  <h1 class="text-h3 mb-4 white--text font-weight-bold line-height mt-9 text-left" v-html="$t('grow_business_primary')"></h1>
                  <p class="white--text text-left">{{ $t('subscriptionText1') }}</p>
                </div>
                <h1 v-html="$t('future_entrepreneur_primary')" v-if="this.$route.name==='auth1.sign-up1'" class="text-h3 mb-4 white--text font-weight-bold line-height mt-9 text-left"></h1>
            </div>
          </div>
          <div class="sign-in-from d-block d-md-none min-vh-100">
            <router-link :to="{ name: 'social.list' }" class="sign-in-logo mt-5 mb-5">
                  <img
                    src="../../assets/images/logo/logo-prosk.png"
                    class="img-fluid "
                    alt="logo"
                  />
              </router-link>
            <v-card class="p-5 border-radius-medium">
            <router-view></router-view>
          </v-card>
          </div>
        </div>
      </v-col>
        <!-- <div class="text-center w-100 mt-5 d-block d-md-none">
          <a class="sign-in-logo mb-5" href="#">
            <img
              src="../../assets/images/logo/logo-prosk.png"
              class="img-fluid"
              alt="logo"
            />
          </a>
        </div> -->
      <v-col md="6" class="bg-white align-center d-none d-md-flex">
        <div class="sign-in-from">
          <router-view></router-view>
        </div>
      </v-col>
    </v-row>
  </section>
 </div>
</template>
<script>
import { socialvue } from '@/config/pluginInit';
import i18ImagesMixin from '@/mixins/i18ImagesMixin';

export default {
  name: 'AuthLayout1',
  mixins: [i18ImagesMixin],
  mounted () {
    socialvue.index();
  },
  data () {
    return {
      bannerRoutes: ['auth1.sign-in1', 'auth1.sign-up1']
    };
  },
  computed: {
    chooseBanner () {
      const routeName = this.$route.name;
      switch (routeName) {
        case 'auth1.sign-in1':
          return this.i18nImage('signin_banner.png');
        case 'auth1.sign-up1':
          return this.i18nImage('signup_banner.png');
        default:
          return '';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.vector-bg {
  background-image: url(~@/assets/images/page-img/Vector_14.png);
  background-position: bottom left;
  background-repeat: no-repeat;
}

.sign-in-logo {
  left: 30px;
}

.container-prosker {
  max-width: 100% !important;

  > .row {
    margin: 0;
    width: 100%;

    > div {
      padding-top: 47px;
      width: 50%;
    }
  }
}

.side-container,
.sign-in-from {
  max-width: 660px;
  padding-bottom: 32px;
}

.center .side-wrapper {
  justify-content: center;
}

.side-wrapper {
  display: flex;
  justify-content: flex-end;
}

.side-container {
  position: relative;
}

.info-container {
  padding: 0 30px;
}
.mtop-15 {
  margin-top:15% !important;
}
</style>
